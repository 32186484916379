.sc-launcher {
  width: 60px;
  height: 60px;
  background-color: #fff;
  border: 5px solid #ff9f1c;
  align-items: center;
  display: flex !important;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  right: 25px;
  bottom: 25px;
  border-radius: 50%;
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out;
  outline: none;
  z-index: 9999;
  justify-content: center;
}

.sc-launcher .sc-open-icon,
.sc-launcher .sc-closed-icon {
  transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
}

.sc-launcher .sc-closed-icon,
.sc-launcher .sc-open-icon {
  transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
  width: 20px;
  height: 20px;
  display: flex;
}

.sc-launcher .sc-open-icon {
}

.sc-launcher.opened .sc-open-icon {
  transform: rotate(-90deg);
  opacity: 1;
}

.sc-launcher.opened .sc-closed-icon {
}

.sc-launcher.opened:before {
  box-shadow: 0px 0px 400px 250px rgba(148, 149, 150, 0.2);
}

.sc-launcher:hover {
  box-shadow: 0 0px 27px 1.5px rgba(0, 0, 0, 0.2);
}

.sc-new-messsages-count {
  position: absolute;
  top: -3px;
  left: 41px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background: #ff4646;
  color: white;
  text-align: center;
  margin: auto;
  font-size: 12px;
  font-weight: 500;
}
/** chat windows **/
.sc-chat-window {
  width: 370px;
  z-index: 1000;
  height: calc(100% - 120px);
  max-height: 590px;
  position: fixed;
  right: 25px;
  bottom: 100px;
  box-sizing: border-box;
  box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sc-chat-window.closed {
  opacity: 0;
  visibility: hidden;
  bottom: 90px;
}

@media (max-width: 450px) {
  .sc-chat-window {
    width: 100%;
    height: 100%;
    max-height: 100%;
    right: 0px;
    bottom: 0px;
    border-radius: 0px;
  }
  .sc-chat-window {
    transition: 0.1s ease-in-out;
  }
  .sc-chat-window.closed {
    bottom: 0px;
  }
}

.sc-user-input {
  margin: 0px;
  position: relative;
  bottom: 0;
  display: flex;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
  flex-direction: row;
}

.sc-user-input--text {
  background: #f5f5fd;
  width: 100%;

  resize: none;
  border: none;
  outline: none;
  box-sizing: border-box;
  padding: 6px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #565867;
  -webkit-font-smoothing: antialiased;
  overflow: scroll;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  justify-content: flex-start;
}

.sc-user-input--text:empty:before {
  content: attr(placeholder);
  display: block;
  color: rgba(86, 88, 103, 0.3);
  outline: none;
}

.sc-user-input.active {
  box-shadow: none;
  background-color: white;
  box-shadow: 0px -5px 20px 0px rgba(150, 165, 190, 0.2);
}

.sc-user-input--button {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sc-user-input--picker-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}
.sc-user-input--send-icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  align-self: center;
  outline: none;
}

.sc-user-input--send-icon path {
  fill: rgba(86, 88, 103, 0.3);
}

.sc-user-input--send-icon:hover path {
  fill: rgba(86, 88, 103, 1);
}

.sc-user-input--emoji-icon-wrapper,
.sc-user-input--send-icon-wrapper {
  background: none;
  border: none;
  padding: 0px;
  margin: 0px;
}

.sc-user-input--send-icon-wrapper:focus {
  outline: none;
}

.sc-user-input--emoji-icon-wrapper:focus {
  outline: none;
}

.sc-user-input--emoji-icon {
  height: 18px;
  cursor: pointer;
  align-self: center;
}

.sc-user-input--emoji-icon path,
.sc-user-input--emoji-icon circle,
.sc-user-input--send-icon path,
.sc-user-input--send-icon circle {
  fill: rgba(86, 88, 103, 0.3);
}

.sc-user-input--emoji-icon-wrapper:focus .sc-user-input--emoji-icon path,
.sc-user-input--emoji-icon-wrapper:focus .sc-user-input--emoji-icon circle,
.sc-user-input--emoji-icon.active path,
.sc-user-input--emoji-icon.active circle,
.sc-user-input--emoji-icon:hover path,
.sc-user-input--emoji-icon:hover circle,
.sc-user-input--send-icon-wrapper:focus .sc-user-input--send-icon path,
.sc-user-input--send-icon-wrapper:focus .sc-user-input--send-icon circle,
.sc-user-input--send-icon.active path,
.sc-user-input--send-icon.active circle,
.sc-user-input--send-icon:hover path,
.sc-user-input--send-icon:hover circle {
  fill: rgba(86, 88, 103, 1);
}

.sc-user-input--send-icon {
  height: 18px;
  cursor: pointer;
  align-self: center;
}

.sc-header {
  background: #ff9f1c;
  min-height: 75px;
  color: white;
  padding: 10px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.sc-header--img {
  border: 3px solid #fff;
  border-radius: 50%;
}

.sc-header--img img {
}

.sc-header--team-name {
  justify-content: center;
  align-items: self-start;
}

.sc-header--close-button {
  width: 40px;
  align-self: center;
  justify-content: center;
  height: 40px;
  margin-right: 10px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 5px;
}

.sc-header--close-button:hover {
  background: rgba(0, 0, 0, 0.2);
}

.sc-header--close-button img {
  box-sizing: border-box;
}

@media (max-width: 450px) {
  .sc-header {
    border-radius: 0px;
  }
}

.sc-emoji-picker {
  position: absolute;
  bottom: 50px;
  right: 0px;
  box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
  background: white;
  border-radius: 10px;
  outline: none;
}

.sc-emoji-picker:after {
  content: "";
  width: 14px;
  height: 14px;
  background: white;
  position: absolute;
  bottom: -6px;
  right: 49px;
  transform: rotate(45deg);
  border-radius: 2px;
}

.sc-message {
  padding: 0px 15px;
  width: 100%;
  display: flex;
}

.sc-message.expand {
  padding-top: 10px;
}

.sc-message--content {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.sc-message--content.sent {
  justify-content: flex-end;
}

.sc-message--avatar {
  align-self: center;
  margin-right: 15px;
}

.sc-message--avatar.hidden {
  visibility: hidden;
}

.sc-message--content---body {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.sc-message--username {
  font-size: 80%;
  font-weight: bold;
}

.sc-message--text {
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  max-width: calc(100% - 120px);
  transition: transform 100ms ease-in-out;
}

.sc-message--text:hover {
  transform: scale(1.05);
}

.sc-message--text h1,
h2,
h3,
h3,
p {
  margin: 2px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.sc-message--text p {
  font-weight: 300;
  font-size: 14px;
}

.sc-message--text a {
  font-weight: 300;
  font-size: 14px;
}

.sc-message--text a:link {
  color: white;
}

.sc-message--text a:visited {
  color: #fff04e;
}

.sc-message--text a:hover {
  color: white;
  text-decoration: none;
}

.sc-message--text a:active {
  color: white;
}

.sc-message--text p {
  word-wrap: break-word;
}

.sc-message--emoji {
  cursor: pointer;
  outline: none;
  transition: transform 100ms ease-in-out;
}

.sc-message--emoji:hover {
  transform: scale(1.1);
}

.sc-message--date {
  margin-bottom: 5px;
  font-size: 70%;
}

.sc-message--content.sent .sc-message--text {
  color: white;
  background-color: #4e8cff;
  align-self: flex-end;
}

.sc-message--content.sent .sc-message--date {
  align-self: flex-end;
}

.sc-message--content.sent .sc-message--emoji {
  align-self: flex-end;
}

.sc-message--content.received .sc-message--text {
  color: #263238;
  background-color: #f4f7f9;
  margin-right: 40px;
  align-self: flex-start;
}

.sc-message--content.received .sc-message--date {
  align-self: flex-start;
}

@media (max-width: 450px) {
  .sc-message {
    width: 80%;
  }
}
.sc-message-list {
  height: 80%;
  overflow-y: auto;
  background-color: white;
  background-size: 100%;
  padding-top: 40px;
}
.sc-message {
  padding: 0px 15px;
  width: 100%-15px;
  display: flex;
}

.sc-message.expand {
  padding-top: 10px;
}

.sc-message--content {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.sc-message--content.sent {
  justify-content: flex-end;
}

.sc-message--avatar {
  align-self: center;
  margin-right: 15px;
}

.sc-message--avatar.hidden {
  visibility: hidden;
}

.sc-message--content---body {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.sc-message--username {
  font-size: 80%;
  font-weight: bold;
}

.sc-message--text {
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  max-width: calc(100% - 120px);
  transition: transform 100ms ease-in-out;
}

.sc-message--text:hover {
  transform: scale(1.05);
}

.sc-message--text h1,
h2,
h3,
h3,
p {
  margin: 2px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.sc-message--text p {
  font-weight: 300;
  font-size: 14px;
}

.sc-message--text a {
  font-weight: 300;
  font-size: 14px;
}

.sc-message--text a:link {
  color: white;
}

.sc-message--text a:visited {
  color: #fff04e;
}

.sc-message--text a:hover {
  color: white;
  text-decoration: none;
}

.sc-message--text a:active {
  color: white;
}

.sc-message--text p {
  word-wrap: break-word;
}

.sc-message--emoji {
  cursor: pointer;
  outline: none;
  transition: transform 100ms ease-in-out;
}

.sc-message--emoji:hover {
  transform: scale(1.1);
}

.sc-message--date {
  margin-bottom: 5px;
  font-size: 70%;
}

.sc-message--content.sent .sc-message--text {
  color: white;
  background-color: #4e8cff;
  align-self: flex-end;
}

.sc-message--content.sent .sc-message--date {
  align-self: flex-end;
}

.sc-message--content.sent .sc-message--emoji {
  align-self: flex-end;
}

.sc-message--content.received .sc-message--text {
  color: #263238;
  background-color: #f4f7f9;
  margin-right: 40px;
  align-self: flex-start;
}

.sc-message--content.received .sc-message--date {
  align-self: flex-start;
}

@media (max-width: 450px) {
  .sc-message {
    width: 80%;
  }
}
